import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import type * as React from 'react'

import { cn } from '#utils/utils'

import { Spinner } from './Spinner'

const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center gap-3 whitespace-nowrap rounded-md text-sm font-semibold transition-colors duration-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-border focus-visible:ring-offset-1 disabled:pointer-events-none disabled:opacity-50 [&>[data-slot="icon"]]:h-3/5 [&>[data-slot="spinner"]]:h-3/5 data-loading:[&>[data-slot="spinner"]]:block data-loading:[&>[data-slot="icon"]]:hidden',
  {
    variants: {
      variant: {
        default: 'bg-brand-800 text-white hover:bg-brand-900',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border-border text-primary bg-background hover:bg-brand-50 border border-border font-medium shadow-sm shadow-secondary',
        soft: 'bg-sky-50 text-sky-800 hover:bg-sky-100 border-sky-200 border font-medium',
        ghost: 'hover:bg-secondary font-medium',
        link: 'text-primary underline-offset-4 hover:underline font-medium'
      },
      size: {
        default: 'h-8 px-4 ',
        xs: 'h-6 px-2 text-xs',
        sm: 'h-7 rounded-md px-3 ',
        lg: 'h-10 rounded-md px-8',
        icon: 'p-0 aspect-square flex [&>[data-slot="icon"]]:h-full'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export type ButtonStyles = VariantProps<typeof buttonVariants>

export interface ButtonProps
  extends React.ComponentProps<'button'>,
    ButtonStyles {
  asChild?: boolean
  loading?: boolean
}

const Button = ({
  className,
  variant,
  size,
  asChild = false,
  loading = false,
  children,
  type = 'button',
  ...props
}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp
      className={cn(buttonVariants({ variant, size }), className)}
      type={type}
      {...props}
      disabled={props.disabled || loading}
      data-loading={loading || undefined}
    >
      <Spinner className="hidden" />
      {children}
    </Comp>
  )
}

export { Button, buttonVariants }
